$white: #ffffff;
$black: #000000;
$clarivate-purple: #673ab7;
$dark-gray: #505050;
$orange: #FF8000;
$light-orange: #FFA200;
$dark-blue: #005A84;
$light-blue: #0083BF;
$dark-purple: #46166B;
$historical-purple: #5937a2;
$light-purple: #6234A4;
$redlines-red: #d01317;
$dark-red: #A00000;
$light-red: #DC0A0A;
$dark-green: #387C2B;
$most-recent-green: #8fa731;
$secure-pdf-green: #559d1d;
$light-green: #78A22F;
$dark-warm-gray: #766C62;
$light-warm-gray: #A0968C;
$medium-cool-gray: #828282;
$light-cool-gray: #DCDCDC;
$bold-dark-gray: #212121;
$bold-orange: #FF5900;
$bold-light-orange: #FF8000;
$bold-dark-blue: #00375E;
$bold-light-blue: #005DA2;
$bold-dark-purple: #270A46;
$bold-light-purple: #3E1B81;
$bold-dark-red: #760000;
$bold-light-red: #B40303;
$bold-dark-green: #1E5615;
$bold-light-green: #4B7914;
$bold-dark-warm-gray: #50473E;
$bold-light-warm-gray: #766B60;
$bold-medium-cool-gray: #5C5C5C;
$bold-light-cool-gray: #979797;
$muted-dark-gray: #777777;
$muted-orange: #FFA500;
$muted-light-orange: #FFC500;
$muted-dark-blue: #0099C4;
$muted-light-blue: #00C3EC;
$muted-dark-purple: #7E2DAC;
$muted-light-purple: #A263AC;
$muted-dark-red: #DE0000;
$muted-light-red: #F81616;
$muted-dark-green: #69BD54;
$muted-light-green: #BEDF5E;
$muted-dark-warm-gray: #B8ADA2;
$muted-light-warm-gray: #DED7CF;
$muted-medium-cool-gray: #C2C2C2;
$muted-light-cool-gray: #ECECEC;
$muted-lighter-cool-gray: #f9f9f9;
$text-color: #595959;
$text-color: #666666;
$quiet-color: $muted-dark-gray;
$loud-color: $bold-dark-gray;
$link-color: $dark-blue;
$link-hover-color: $light-orange;
$link-focus-color: $bold-dark-blue;
$link-active-color: $bold-dark-blue;
$link-visited-color: $link-color;

$secondary-link-color: #0099C4;
$secondary-link-hover-color: #00c3ec;

$success-color: $dark-green;
$success-bg-color: $light-green;
$success-border-color: $dark-green;
$error-color: $light-red;
$error-bg-color: #FFD1D1;
$error-border-color: $dark-red;

// Greys
$dark-grey: #555555;
