@import 'colors';
@import 'mixins';

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Osygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  display: table-cell;
  vertical-align: middle;
}
.content {
  height:auto !important;
}
app-root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}

mat-sidenav-container, .content {
  margin: 0;
  width: 100%;
  height: 100%;
}

mat-toolbar.navBar span.fill, mat-toolbar.titleBar span.fill, mat-card-actions span.fill {
  flex: 1;
}
mat-toolbar.titleBar, .searchBar .mat-tab-header {
  position: sticky;
  top:0;
  right:0;
  left:0;
  z-index: 2;
}
/**** Primary Toolbar - Vulcan ****/
mat-toolbar.navBar {
  height: 75px;
  background-color: $black;
  button {
    position: relative;
    vertical-align: middle;
    align-items: center;
    margin-right:.5em;
  }
  .vulcan {
    color: $white;
  }
  &.testing {
    .vulcan { color: #1dcc00; }
    .userInfo button {
      color: lightgreen;
      background-color: green;
      border-color: lime;
      border-width: 2px;
      border-style: solid;
    }
  }
  mat-form-field {
    padding-top:.5em;
    color: $white;
    .mat-input-underline{
      border-bottom: 1px solid $white;
    }
  }

  app-shopping-summary {
    span {
      color: $white;
      text-decoration: none;

      a {
        color: $white;
        text-decoration: none;
      }
    }
  }
}
/**** Override Style Sheet Rules Start *****/
.mat-form-field {
  width: auto !important;
}
.mat-form-field-infix{
  width: auto !important;
}
.mat-select {
 min-width: 180px;
}

/**** Override Style Sheet Rules End *****/

/**** Primary Toolbar Vulcan - End ****/
mat-tab-group.searchBar {
  .mat-tab-header {
    background: $light-cool-gray !important;
    min-height: 64px;
    .mat-tab-label {
      padding-top: .5em;
      mat-icon {
        vertical-align: middle;
      }
    }
    app-results-tab-label span.no-results{
      color: gray;
    }
    div.mat-tab-label[aria-selected=true] {
      background: $light-cool-gray;
      color: #5b2c98 !important;  
      .no-results {
        color: #5b2c98 !important;
      }
    }
  }
}
/**** Secondary Navigation Toolbar ****/
.titleBar {
  @include secondary-navigation;
  background: $light-cool-gray !important;
  nav {
    border:0;
    a {
      min-width: 8%;
      padding: 0 20px;
      mat-icon {
        font-size:26px;
        color: $bold-dark-gray;
      }
      &.mat-tab-link[ng-reflect-active="true"] {
        opacity: 1;
        color: $black;
      }
    }
  }
  .profile {
    vertical-align: middle;
    font-size:17px;
    color: $clarivate-purple;
    mat-icon {
      vertical-align: middle;
    }
    a.no-log mat-icon {
      color: black;
    }
  }
}

/**** Secondary Navigation Toolbar End ****/

/************ Side Navigation ***********/

mat-sidenav.mainNavigation {
  min-width: 5vw;
  @include box-shadow;
  width: auto;
  bottom: 0;
  overflow: auto;
  height: 100%;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      color: red;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      margin: 0;
      padding: 0;
      a {
        box-sizing: border-box;
        display: block;
        font-size: 14px;
        font-weight: 400;
        line-height: 47px;
        text-decoration: none;
        transition: all .3s;
        padding: 0 16px;
        position: relative;
        &.active {
          font-weight: 600;
        }
      }
      &:last-child {
        border-color: transparent;
      }
    }
  }
}

/************ Style Guide CSS ***********/
/***** General Styling ******/

h1 {
  font-size: 32px;
  margin-top: .5em;
  line-height: 32px;
}

h2 {
  font-size: 24px;
  line-height: 24px;
  margin:.5em 0;
}

h3, mat-toolbar-row h3, mat-grid-tile-header h3 {
  line-height: 20px !important;
  font-size:18px !important;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
mat-divider {
  padding:.25em 0 !important;
  margin:.25em 0 !important;
}
.sidenav-content {
  margin-left: 20px;
  padding-right: 50px;
  overflow: hidden;
}

.mat-sidenav-backdrop {
  background: none;
}

router-outlet + * {
  flex-grow: 1;
}

.bg-gray {
  background-color: #eeeeee;
}
.warn-text {
  color: #ff0000;
}

.error {
  color: red;
}
div.page {
  padding: 1em;
}
.spacer {
  flex:1 1 auto;
}
a, [ng-reflect-router-link] {
  cursor: pointer;
  mat-icon {
    vertical-align: middle;
  }
}

mat-table {
  mat-row {
    mat-cell {
      &:last-child {
        a {
          mat-icon {
            color: $black;
          }
       }
     }
   }
   mat-cell.has-icon-group-column {
     justify-content: space-between;
   }
 }
}


/***** Card Styling ******/
mat-card {
  margin-right: 1em;
  .mat-card-title {
    font-size: 16px;
  }
}

/***** FORMS - 2 COLUMN STYLING ******/

form.two-column {
  width: 100%;
  h4 {
    margin: 0.25em 0;
    text-align: center;
  }
  button {
    width: 45%;
  }
  .mat-form-field, mat-select {
    padding: .5em .5em 0 0;
  }
  mat-checkbox {
    display: block;
    padding:1em 0;
  }
  mat-checkbox.half-width {
    display: inline-block;
    padding: .5em .5em 0 0;
    width: 40%
  }
}
/***** FORMS Modal Plain ******/
form.plain {
  h4 {
    text-align: center;
    margin:.25em 0;
  }
  button {
    width: 49%;
  }
  mat-checkbox {
    display: block;
    padding: 1em 0;
  }
}
/***** FORMS Modal Plain ******/

/***** FORMS 1 COLUMN STYLING ******/

form.single-column {
  width: 100%;
  h4 {
    margin: 0;
    text-align: center;
  }
  .mat-form-field, mat-select {
    padding:.5em 0 0 0;
  }
  button {
    width:49%;
  }
}

/***** GRID FORM *****/
form.grid {
  mat-grid-list {
    mat-grid-tile {
      .mat-grid-tile-content {
        display: block;
        background: #fff;
        padding: 1em;
        @include box-shadow;
        mat-grid-list {
          mat-grid-tile {
            .mat-grid-tile-content {
              padding:0;
              box-shadow: none;
            }
          }
        }
      }
      &.actions {
        .mat-grid-tile-content {
          background: transparent;
        }
      }
    }

  }
  button {
    width: 45%;
  }
}
/************ Tables **************/

/**** Material Design Table Start*****/
.page.single-table {
  margin: 0 1em;
  h2 {
    margin: .5em 0;
  }
  .table-container {
    @include box-shadow;
    mat-toolbar {
      a {
        mat-icon {
          padding-left: .25em;
        }
      }
    }
    mat-table {
      .mat-column-actions {
        text-align: right;
        justify-content: flex-end;
      }
    }
  }
}

mat-form-field.full-width-textarea-container {
  display: block;
  .mat-form-field-infix{
    width:  initial;
  }
}

/**** Material Design Table End *****/
.page.single-table.grid {
  margin:0;
  mat-grid-list {
    mat-grid-tile {
      @include table-box-shadow;
      .mat-grid-tile-content {
        background: $white;
      }
    }
  }
  .table-container {
    margin-top:1.5em;
  }
}
/****** Grid With Background *****/

.single-grid {
  mat-grid-list {
    mat-grid-tile {
      .mat-grid-tile-content {
        display: block;
        background: #fff;
        padding: 0 1em;
        margin: .5em;
        height:auto;
        @include table-box-shadow;
      }
    }
  }
}

/***** Grid with Background *****/

/**** Page with grid tables *****/
.page.grid {
  mat-grid-list {
    mat-grid-tile {
      h4 {
        margin:0.25em 0;
      }
      .mat-grid-tile-content {
        display: block;
        background: transparent;
        padding: 1em;
        @include table-box-shadow;
        mat-toolbar {
          background:$muted-light-cool-gray;
          @include mat-toolbar-shadow;
          a {
            mat-icon {
              vertical-align: text-top;
            }
          }
        }
        table {
          font-style: 14px;
          thead {
            tr {
              th {
                &:last-child {
                  text-align: right;
                }
              }
            }
          }
          tfoot {
            tr {
              td {
                &:last-child {
                  text-align: right;
                }
              }
            }
          }
          tbody {
            tr {
              td {
                &:last-child {
                  text-align:right;
                }
              }
            }
          }
        }
      }
    }
  }
}

/**** Vulcan panel (decorates grid list w/ tile)  *****/
mat-grid-list.v-panel-grid {
  mat-grid-tile.v-panel {
    .mat-grid-tile-content {
      border: 0;
      mat-grid-tile-header {
        background: $muted-light-cool-gray;
        color: $black;
        font-weight: bolder;
      }
      .v-panel-inner {
        height: calc(100% - (2 * 1em));
        @include box-shadow;
        position: relative;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        border-top: 1px solid #ccc;
        mat-grid-tile-header {
          position: static;
        }
      }
      .v-panel-content {
        height: 100%;
        flex: 1;
        padding: 1em;
        box-sizing: border-box;
        &.v-docking-context-vertical {
          display: flex;
          flex-direction: column;
        }
      }
      .v-dock-main {
        flex: 1;
      }
    }
  }
}


/**** Modal w/ Text Area & Table*****/
.modal.single-table {
  h3 {
    text-align: center;
    margin: 0;
    padding-bottom: .5em;
  }
  form {
    overflow: hidden;
    padding: 0 0 10px 0;
    mat-form-field {
      textarea {
        width: 900px;
        height: 100px;
      }
    }
    button {
      float: right;
    }
  }
  table {
    width:100%;
    display: flex;
    flex-direction: column;
    font-size:14px;
    thead {
      font-size: 14px;
      text-align: left;
      tr {
        padding: 12px 7px;
        background-color: transparent !important;
        display: flex;
        border-bottom: 1px solid #ccc;
        padding: 7px;
        &:hover {
          background:none;
        }
      }
    }
    tbody {
      font-size: 12px;
      tr {
        display: flex;
        border-bottom: 1px solid #ccc;
        padding: 7px;
        &:nth-child(even) {
          background-color: #f5f5f5;
        }
        &:hover {
          background-color: #e4e4e4;
        }
      }
    }
    td, th {
      flex: 1;
      color: rgba(0, 0, 0, 0.87);
      padding: 2px 0px;
      overflow: hidden;
      text-align: left;
    }
  }

}
/**** Global Table Style Start*****/


  table {
    margin: 0;
    width: 100%;
    font-size:14px;
    @include table-box-shadow;
    thead, tfoot {
      tr {
        border-bottom: 1px solid #ccc;
        padding: 12px;
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid #ccc;
        padding: 12px;
        &:nth-child(even) {
          background-color: #f5f5f5;
        }
        &:hover {
          background-color: #e4e4e4;
        }
      }
    }
    td, th {
      padding: 10px 12px;
      overflow: hidden;
      text-align: left;
    }
  }
/**** Global Table Style End*****/
app-retail-order-details div.page {
  padding:0;
}
.mat-row:nth-child(even) {
  background-color: #f5f5f5;
}

.mat-row:hover {
  background-color: #e4e4e4;
}


.mat-form-field {
  padding-bottom: 1%;
}
.add-new-btn .mat-icon {
  position: relative;
  top: 5px;
}

.cust-table .toolbar{
  float: left;
  width: 90%;
}

.cust-table .add-new-btn {
  float: right;
  width: 10%;
  line-height: 64px;
  background: whitesmoke
}

// Alert Panel START
.alert-error {
  background: #fff !important;
  color: #000 !important;
}
.alert-error button {
  color: #ff0000 !important;
  font-weight: bold;
}
.alert-success {
  background: #fff !important;
  color: #000 !important;
}
.alert-success button {
  color: #1dcc00 !important;
  font-weight: bold;
}
// Alert Panel END

.clickable {
  cursor: pointer;
}

.not-clickable {
  cursor: default !important;
}

.tile {
  padding: 1em;
  background: #fff;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
}

.tile.next {
  margin-top: 32px;
}

.d-none {
  display: none !important;
}

.w100 {
  width: 100% !important;
}
.w50 {
  width: 50% !important;
}
.w45 {
  width: 45% !important;
}
.w40 {
  width: 40% !important;
}
.w25 {
  width: 25% !important;
}
.w20 {
  width: 20% !important;
}
.w15 {
  width: 15% !important;
}
.w10 {
  width: 10% !important;
}
.w5 {
  width: 5% !important;
}

.ck-editor__editable_inline {
  min-height: 350px;
  max-height: 350px;
}

.d-flex {
  display: flex;
}

.between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.modal-content {
  overflow-y: auto;
}

mat-dialog-content {
  max-height: 85vh !important;
}

.icon-disabled {
  color: #9e9e9e;
  cursor: auto;
  pointer-events: none;
}

.input-icon-disabled {
  pointer-events: none;
  
  .mat-button-wrapper {
    color: #9e9e9e;
    cursor: auto;
  }
}

.mat-icon.mat-warn {
  color: #f44336;
}

.mat-icon.mat-primary {
  color: #673ab7;
}
