@mixin box-shadow {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
@mixin table-box-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
@mixin secondary-navigation {
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.45);
}
@mixin mat-toolbar-shadow {
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.3);
}
@mixin display-inline-block {
  display: inline-block;
  zoom: 1;
  vertical-align: top;

}
